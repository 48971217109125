export interface GoogleTagsManagerProps {
  /**
   * Google Analytics Measurement ID
   * If not provided or empty string, nothing will be rendered
   */
  id?: string
}

export const GoogleTagsManager: React.FC<GoogleTagsManagerProps> = ({ id }) => {
  if (id == null || id === '') return null

  return (
    <>
      <script src={`https://www.googletagmanager.com/gtag/js?id=${id}`} />
      <script id="google-analytics">
        {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${id}');
      `}
      </script>
    </>
  )
}
