import MuiCardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'

import type { TermLifeQuotePremium } from '../types'

import { BenefitAmountSection } from './BenefitAmountSection'
import { PremiumSection } from './PremiumSection'
import { TermPeriodSection } from './TermPeriodSection'

import type { TermLifeTermPeriod } from '@/gql/graphql'

export interface CardContentProps {
  loading: boolean
  termPeriod: TermLifeTermPeriod
  benefitAmount: number
  premium?: TermLifeQuotePremium
}

export const CardContent: React.FC<CardContentProps> = ({
  loading,
  termPeriod,
  benefitAmount,
  premium,
}) => (
  <MuiCardContent>
    <Stack spacing={2}>
      <TermPeriodSection termPeriod={termPeriod} />

      <Divider />

      <BenefitAmountSection benefitAmount={benefitAmount} />

      <Divider />

      <PremiumSection loading={loading} premium={premium} />
    </Stack>
  </MuiCardContent>
)
