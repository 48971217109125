import Typography from '@mui/material/Typography'

import { TermLifeTermPeriod } from '@/gql/graphql'

export interface BenefitPeriodProps {
  termPeriod: TermLifeTermPeriod
}

const TermPeriodText = {
  [TermLifeTermPeriod.TenYears]: '10 Years',
  [TermLifeTermPeriod.FifteenYears]: '15 Years',
  [TermLifeTermPeriod.TwentyYears]: '20 Years',
  [TermLifeTermPeriod.ThirtyYears]: '30 Years',
}

export const BenefitPeriod: React.FC<BenefitPeriodProps> = ({ termPeriod }) => {
  return (
    <Typography component="span" variant="h4" fontWeight={400}>
      {TermPeriodText[termPeriod]}
    </Typography>
  )
}
