import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { QuoteCard } from '../QuoteCard'

import { IncomeProtectionAcceptedQuote } from './IncomeProtectionAcceptedQuote'

import { IncomeProtectionCoverageType } from '@/gql/graphql'

export const AcceptedQuoteCard: React.FC = () => {
  const params = useParams()
  const appId = params.appId ?? ''

  const { loading, data, error } = useQuery(IncomeProtectionAcceptedQuote, {
    variables: { appId },
    skip: appId === '',
  })

  if (error != null) throw error
  if (
    loading ||
    data?.insuranceApplication?.product.__typename !==
      'IncomeProtectionProduct' ||
    data.insuranceApplication.product.applicant?.__typename !==
      'IncomeProtectionApplicant'
  ) {
    return
  }

  const coverages = data.insuranceApplication.product.applicant.coverages
  const quotes = data.insuranceApplication.product.quotes

  const baseCoverage = coverages.find(
    ({ coverageType }) => coverageType === IncomeProtectionCoverageType.Base,
  )

  const totalQuote = quotes.find(
    ({ coverageType }) => coverageType === IncomeProtectionCoverageType.Total,
  )

  if (baseCoverage == null || totalQuote == null) {
    return
  }

  const { benefitAmount, coverageType, eliminationPeriod, benefitPeriod } =
    baseCoverage
  const { paymentFrequency, premiumAmount } = totalQuote

  return (
    <QuoteCard
      title="Disability"
      baseCoverage={{
        benefitAmount,
        eliminationPeriod,
        benefitPeriod,
        coverageType,
      }}
      totalPremium={{ paymentFrequency, premiumAmount }}
    />
  )
}
