import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'

export interface LimitationsAndExclusionsDialogProps {
  open: boolean
  handleClose: () => void
}

export const LimitationsAndExclusionsDialog: React.FC<
  LimitationsAndExclusionsDialogProps
> = ({ open, handleClose }) => (
  <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
    <DialogTitle>Limitations and Exclusions</DialogTitle>

    <DialogContent dividers>
      <Typography sx={{ mb: 3 }} gutterBottom variant="h3">
        Standard Policy Limitations and Exclusions (Form No. I L1702)
      </Typography>

      <Typography sx={{ mb: 3 }} gutterBottom>
        <b>Note:</b> Limitations and Exclusions may vary by state. The state
        specific policy form is the ultimate authority for any questions about
        this product.
      </Typography>

      <Typography sx={{ mb: 3 }} gutterBottom>
        <u>Misstatement of Age and/or Gender</u> - If the Insured Person’s age
        and/or gender is misstated in the Application, this policy’s and any
        applicable riders’ benefit amounts will be revised to the amount the
        premium paid would have purchased for the correct age and/or gender
        using published rates in effect on the Issue Date.
      </Typography>

      <Typography sx={{ mb: 3 }} gutterBottom>
        <u>Suicide</u> - If the Insured Person dies by suicide within two years
        of the Issue Date, liability is limited to a refund of premiums paid,
        less benefits paid under any riders. If the Insured Person dies by
        suicide within two years of the last Reinstatement Date, liability is
        limited to a refund of premiums paid since the last Reinstatement Date,
        less benefits paid under any riders.
      </Typography>

      <Typography sx={{ mb: 3 }} gutterBottom>
        <u>Contestable Period</u> – The validity of this policy and any attached
        riders may be contested based on material misrepresentations made in the
        initial Application. However, validity of this policy or any attached
        riders may not be contested after it has been in force during the
        Insured Person’s lifetime for two years from the Issue Date, except for
        fraudulent misstatements in the Application when permitted by applicable
        law in the state where this policy is delivered or issued for delivery.
      </Typography>

      <Typography sx={{ mb: 3 }} gutterBottom variant="h4">
        Accident-Only Disability Income Rider Standard Limitations and
        Exclusions (Form No. R I0827-T)
      </Typography>

      <Typography sx={{ mb: 3 }} gutterBottom>
        The Monthly Benefit will only be paid to the insured if the insured
        becomes totally disabled as the result of and within 180 days of a
        covered accident occurring prior to the insured’s 65th birthday.
      </Typography>

      <Typography sx={{ mb: 3 }} gutterBottom>
        Benefits are not paid under this rider for Total Disabilities that
        result from the Insured Person:
      </Typography>

      <ul>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            being exposed to war or any act of war, declared or undeclared;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            actively serving in any of the armed forces, or unit auxiliary
            thereto, including the National Guard or Army Reserve;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            self-inflicting an injury intentionally;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            participating in or attempting to commit a felony;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            engaging in an illegal occupation;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            committing or attempting to commit suicide, whether sane or insane;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            using drugs or alcohol except for prescribed drugs taken as
            prescribed;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            piloting a non-commercial aircraft more than 150 hours annually;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            “flying for pay” an aircraft outside of established air routes in
            the United States or Canada;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            involved in motor vehicle or boat racing, hang gliding, sky diving,
            mountain or rock climbing, underwater diving and professional
            sports;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            traveling outside of the United States or Canada for more than 14
            days;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            operating a motor vehicle while under the influenced alcohol or
            drugs; or
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            a Preexisting Condition during the first 24 months following the
            Issue Date of this rider.
          </Typography>
        </li>
      </ul>

      <Typography sx={{ mb: 3 }} gutterBottom variant="h4">
        Monthly Disability Income Rider Standard Limitations and Exclusions
        (Form No. R I0825-T)
      </Typography>

      <Typography sx={{ mb: 3 }} gutterBottom>
        The Monthly Benefit will only be paid to the insured if the insured
        becomes totally disabled prior to their 65th birthday.
      </Typography>

      <Typography sx={{ mb: 3 }} gutterBottom>
        Benefits are not paid for Total Disabilities that result from:
      </Typography>

      <ul>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            being exposed to war or any act of war, declared or undeclared;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            actively serving in any of the armed forces, or unit auxiliary
            thereto, including the National Guard or Army Reserve;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            self-inflicting an injury intentionally;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            participating in or attempting to commit a felony;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            engaging in an illegal occupation;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            normal pregnancy (except for Complications of Pregnancy), childbirth
            or elective abortion; or
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            a Preexisting Condition during the first 24 months following the
            Issue Date of this rider.
          </Typography>
        </li>
      </ul>

      <Typography sx={{ mb: 3 }} gutterBottom variant="h4">
        Critical Illness Rider Standard Limitations and Exclusions (Form No. R
        I0762)
      </Typography>

      <Typography sx={{ mb: 3 }} gutterBottom>
        Benefits are payable for the insured’s first ever diagnosis or procedure
        for one of the specified critical illnesses covered in the rider only
        if:
      </Typography>

      <ul>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            the date of diagnosis is while coverage under this rider is in
            force; and
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            the specified critical illness definition is satisfied; and
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            the specified critical illness is not excluded by name or specific
            description.
          </Typography>
        </li>
      </ul>

      <Typography sx={{ mb: 3 }} gutterBottom>
        <u>Waiting Period</u>
      </Typography>

      <Typography sx={{ mb: 3 }} gutterBottom>
        If within 90 days following the Issue Date or last reinstatement date of
        this rider, the Insured Person:
      </Typography>

      <ul>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            receives a First Ever Diagnosis of having Invasive Cancer or
            Carcinoma in Situ; or
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            has exhibited any common or identifiable symptoms or medical
            problems which lead to a Diagnosis of Invasive Cancer or Carcinoma
            in Situ and would cause an ordinary prudent person to seek medical
            advice or treatment
          </Typography>
        </li>
      </ul>

      <Typography sx={{ mb: 3 }} gutterBottom>
        A reduced percentage of the Benefit Amount will be paid. The percentage
        payable will be:
      </Typography>

      <ul>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            10% of the Benefit Amount for Invasive Cancer; or
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            2.5% of the Benefit Amount for Carcinoma in Situ.
          </Typography>
        </li>
      </ul>

      <Typography sx={{ mb: 3 }} gutterBottom>
        Benefits will not be paid under this rider for conditions caused by or
        as the result of the Insured Person:
      </Typography>

      <ul>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            being addicted to drugs or suffering from alcoholism;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            being under the influence of an excitant, depressant, hallucinogen,
            narcotic, or any other drug or intoxicant, including those
            prescribed by a Physician that are misused;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            receiving injuries caused directly or indirectly while under the
            influence of a controlled substance or by intoxication as defined by
            the laws and jurisdiction of the geographical area in which the loss
            or cause of loss was incurred;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            participating in or attempting to commit a felony;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            being exposed to war or any act of war, declared or undeclared;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            actively serving in any of the armed forces, or unit auxiliary
            thereto, including the National Guard or Army Reserve;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            being incarcerated in a penal institution or government detention
            facility;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            engaging in an illegal occupation;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            self-inflicting an injury intentionally; or
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            committing or attempting to commit suicide, while sane or insane.
          </Typography>
        </li>
      </ul>

      <Typography sx={{ mb: 3 }} gutterBottom variant="h4">
        Disability Waiver of Premium Rider Standard Limitations and Exclusions
        (Form No. R I1703)
      </Typography>

      <Typography sx={{ mb: 3 }} gutterBottom>
        The premium waived will correspond to the Premium Mode in effect for the
        policy. Until the Insured Person’s Total Disability claim is approved,
        premiums must continue to be paid when due to avoid a lapse of the
        policy. If the Insured Person is not Totally Disabled on their 65th
        birthday, this rider’s benefits will no longer be provided for any Total
        Disability.
      </Typography>

      <Typography sx={{ mb: 3 }} gutterBottom>
        This rider does not cover Total Disability caused by:
      </Typography>

      <ul>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            war or any act of war, declared or undeclared;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            any attempt at suicide or intentionally self-inflicting an Injury or
            Sickness, while sane or insane;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            participating in a riot, insurrection or an act of terrorism;
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            being intoxicated (as defined by the jurisdiction where loss occurs)
            or under the influence of an illegal substance or any drug (except
            as prescribed to the Insured Person by a Physician); or
          </Typography>
        </li>
        <li>
          <Typography sx={{ mb: 3 }} gutterBottom>
            committing or attempting to commit a felony
          </Typography>
        </li>
      </ul>
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={handleClose}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
)
