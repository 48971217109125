import { useQuery } from '@apollo/client'
import { useMatches, useParams } from 'react-router-dom'

import { ApplicationState } from './ApplicationState'

import { InsuranceApplicationState, InsuranceType } from '@/gql/graphql'

export type GuardState = 'skipped' | 'loading' | 'allowed' | 'denied'

export const StatePropagation = [
  InsuranceApplicationState.Initial,
  InsuranceApplicationState.Submitted,
  InsuranceApplicationState.Qualified,
  InsuranceApplicationState.Sealed,
  InsuranceApplicationState.Finalized,
]

type URLGenerator = (appId: string) => string

export const RedirectPathFn: Record<
  InsuranceType,
  Record<InsuranceApplicationState, URLGenerator>
> = {
  [InsuranceType.TermLife]: {
    [InsuranceApplicationState.Initial]: (appId: string) =>
      `/life/${appId}/basic-info`,
    [InsuranceApplicationState.Submitted]: (appId: string) =>
      `/life/${appId}/interview-questions`,
    [InsuranceApplicationState.Qualified]: (appId: string) =>
      `/life/${appId}/decision`,
    [InsuranceApplicationState.Sealed]: (appId: string) =>
      `/life/${appId}/documents`,
    [InsuranceApplicationState.Signed]: (appId: string) =>
      `/life/${appId}/complete`,
    [InsuranceApplicationState.Finalized]: (appId: string) =>
      `/life/${appId}/complete`,
  },
  [InsuranceType.IncomeProtection]: {
    [InsuranceApplicationState.Initial]: (appId: string) =>
      `/disability/${appId}/basic-info`,
    [InsuranceApplicationState.Submitted]: (appId: string) =>
      `/disability/${appId}/interview-questions`,
    [InsuranceApplicationState.Qualified]: (appId: string) =>
      `/disability/${appId}/decision`,
    [InsuranceApplicationState.Sealed]: (appId: string) =>
      `/disability/${appId}/documents`,
    [InsuranceApplicationState.Signed]: (appId: string) =>
      `/disability/${appId}/complete`,
    [InsuranceApplicationState.Finalized]: (appId: string) =>
      `/disability/${appId}/complete`,
  },
}

interface StateHandle {
  allowedStates: InsuranceApplicationState[]
}

export interface useNavigationGuardReturn {
  guard: GuardState
  redirectPath?: string
}

function hasStateHandle(handle: unknown): handle is StateHandle {
  if (handle == null) return false

  return Array.isArray((handle as StateHandle).allowedStates)
}

export function useNavigationGuard(): useNavigationGuardReturn {
  const params = useParams()
  const matches = useMatches()
  const appId = params.appId ?? ''
  const { loading, data, error } = useQuery(ApplicationState, {
    variables: { id: appId },
    skip: appId === '',
  })

  if (error != null) {
    throw error
  }

  // skipped due to appId is not in the params
  if (!loading && data == null) {
    return { guard: 'skipped' }
  }

  // skipped due to route `handle` does not specify `state`
  const matchIndex = matches.findIndex(({ handle }) => hasStateHandle(handle))
  if (matchIndex === -1) {
    return { guard: 'skipped' }
  }

  // loading the application state
  if (loading) return { guard: 'loading' }

  // this should never happen
  if (data?.insuranceApplication?.__typename !== 'InsuranceApplication') {
    throw new Error('Cannot retrieve insurance application!')
  }

  // get the state from route handle
  const { allowedStates } = matches[matchIndex].handle as StateHandle
  const { state: currentState, type } = data.insuranceApplication

  const guard = allowedStates.includes(currentState) ? 'allowed' : 'denied'
  const redirectPathFn = RedirectPathFn[type][currentState]
  const redirectPath = guard === 'denied' ? redirectPathFn(appId) : undefined

  return {
    guard,
    redirectPath,
  }
}
