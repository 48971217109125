import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { useState } from 'react'

import { LimitationsAndExclusionsDialog } from './LimitationsAndExclusionsDialog'

export const LegalDisclaimer: React.FC = () => {
  const [open, setOpen] = useState(false)

  const handleDialogOpen: React.MouseEventHandler = (e): void => {
    e.preventDefault()
    setOpen(true)
  }

  const handleDialogClose = (): void => {
    setOpen(false)
  }

  return (
    <Box mb={1}>
      <Typography
        fontSize="10px"
        lineHeight="12px"
        color="grey.400"
        padding={0}
      >
        This policy may contain reductions of benefits, limitations and
        exclusions. Click here for standard{' '}
        <Link href="" onClick={handleDialogOpen} color="grey.400">
          Limitations and Exclusions
        </Link>
        . Product availability, features, rates, limitations and exclusions may
        vary by state. For costs and complete details of the coverage, please
        contact your insurance representative or Assurity to review the policy
        for more information. The state specific policy form is the ultimate
        authority for any questions about this product. Policy Form No. I H2016
        and, if made available, associated Form Nos. R I2019, R I2020, I R2021,
        R I2022, R I2023, R I2024, and R I2025 are underwritten by Assurity Life
        Insurance Company, Lincoln, Nebraska. Assurity reserves the right to
        order, at the company’s expense, evidence of insurability which the
        company feels is necessary for the prudent evaluation of the risk on any
        application. Assurity is a marketing name for the mutual holding company
        Assurity Group, Inc. and its subsidiaries. Those subsidiaries include
        but are not limited to: Assurity Life Insurance Company and Assurity
        Life Insurance Company of New York. Insurance products and services are
        offered by Assurity Life Insurance Company in all states except New
        York. In New York, insurance products and services are offered by
        Assurity Life Insurance Company of New York, Albany, NY.
      </Typography>
      <LimitationsAndExclusionsDialog
        open={open}
        handleClose={handleDialogClose}
      ></LimitationsAndExclusionsDialog>
    </Box>
  )
}
