import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { IncomeProtectionBenefitPeriod } from '@/gql/graphql'

const BenefitPeriodText = {
  [IncomeProtectionBenefitPeriod.ThirteenWeeks]: '13 Weeks',
  [IncomeProtectionBenefitPeriod.TwentySixWeeks]: '26 Weeks',
  [IncomeProtectionBenefitPeriod.OneYear]: '1 Year',
  [IncomeProtectionBenefitPeriod.TwoYears]: '2 Years',
  [IncomeProtectionBenefitPeriod.FiveYears]: '5 Years',
  [IncomeProtectionBenefitPeriod.TenYears]: '10 Years',
}

export interface BenefitPeriodSectionProps {
  benefitPeriod: IncomeProtectionBenefitPeriod
}

export const BenefitPeriodSection: React.FC<BenefitPeriodSectionProps> = ({
  benefitPeriod,
}) => {
  return (
    <Box>
      <Typography variant="body2">Coverage Period</Typography>
      <Typography
        variant="body2"
        gutterBottom
        aria-label={BenefitPeriodText[benefitPeriod]}
        fontWeight={600}
      >
        {BenefitPeriodText[benefitPeriod]}
      </Typography>
    </Box>
  )
}
