import { graphql } from '@/gql'

export const CreateInsuranceApplication = graphql(`
  mutation CreateInsuranceApplication(
    $email: EmailAddress!
    $firstName: String!
    $insuranceType: InsuranceType!
    $lastName: String!
    $middleName: String
    $suffix: String
    $agentId: String
  ) {
    createInsuranceApplication(
      input: {
        insuranceType: $insuranceType
        agentId: $agentId
        product: {
          applicant: {
            personalInformation: {
              email: $email
              name: {
                first: $firstName
                middle: $middleName
                last: $lastName
                suffix: $suffix
              }
            }
          }
        }
      }
    ) {
      id
    }
  }
`)
