import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { useState } from 'react'

import { LimitationsAndExclusionsDialog } from './LimitationsAndExclusionsDialog'

export const LegalDisclaimer: React.FC = () => {
  const [open, setOpen] = useState(false)

  const handleDialogOpen: React.MouseEventHandler = (e): void => {
    e.preventDefault()
    setOpen(true)
  }

  const handleDialogClose = (): void => {
    setOpen(false)
  }

  return (
    <Box mb={1}>
      <Typography
        fontSize="10px"
        lineHeight="12px"
        color="grey.400"
        padding={0}
      >
        POLICY NOT AVAILABLE FOR RESIDENTS OF NEW YORK. This policy may contain
        reductions of benefits, limitations and exclusions. Click here for
        standard{' '}
        <Link href="" onClick={handleDialogOpen} color="grey.400">
          Limitations and Exclusions
        </Link>
        . Product availability, features, rates, limitations and exclusions may
        vary by state. For costs and complete details of the coverage, please
        contact your insurance representative or Assurity to review the policy
        for more information. The state specific policy form is the ultimate
        authority for any questions about this product. Policy Form No. I L1702
        and if made available, associated Form Nos. R I1506, R I1703, R I1705, R
        I1706, R I0762, R I0825-T and R I0827-T underwritten by Assurity Life
        Insurance Company, Lincoln, Nebraska. Assurity reserves the right to
        order, at the company’s expense, evidence of insurability which the
        company feels is necessary for the prudent evaluation of the risk.
        Assurity is a marketing name for the mutual holding company Assurity
        Group, Inc. and its subsidiaries. Those subsidiaries include but are not
        limited to: Assurity Life Insurance Company and Assurity Life Insurance
        Company of New York. Insurance products and services are offered by
        Assurity Life Insurance Company in all states except New York. In New
        York, insurance products and services are offered by Assurity Life
        Insurance Company of New York, Albany, NY.
      </Typography>
      <LimitationsAndExclusionsDialog
        open={open}
        handleClose={handleDialogClose}
      ></LimitationsAndExclusionsDialog>
    </Box>
  )
}
