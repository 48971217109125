import type { IncomeProtectionQuotePremium } from '../../types'

import { Loaded } from './Loaded'
import { Loading } from './Loading'
import { NotAvailable } from './NotAvailable'

export interface PremiumSectionProps {
  loading: boolean
  premium?: IncomeProtectionQuotePremium
}

export const PremiumSection: React.FC<PremiumSectionProps> = ({
  loading,
  premium,
}) => {
  if (loading) {
    return <Loading />
  }

  if (premium == null) {
    return <NotAvailable />
  }

  const { premiumAmount, paymentFrequency } = premium

  return (
    <Loaded premiumAmount={premiumAmount} paymentFrequency={paymentFrequency} />
  )
}
