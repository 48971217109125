import Typography from '@mui/material/Typography'

import { CurrencyFormat } from '@/common/components/shared/CurrencyFormat'
import { PaymentFrequency } from '@/gql/graphql'

const PaymentFrequencyText = {
  [PaymentFrequency.Monthly]: '/ month',
  [PaymentFrequency.Quarterly]: '/ 3 months',
  [PaymentFrequency.SemiAnnual]: '/ 6 months',
  [PaymentFrequency.Annual]: '/ year',
}

export interface LoadedProps {
  premiumAmount: number
  paymentFrequency: PaymentFrequency
}

export const Loaded: React.FC<LoadedProps> = ({
  premiumAmount,
  paymentFrequency,
}) => (
  <Typography variant="h2" component="div">
    <Typography
      variant="inherit"
      color="primary"
      display="inline"
      component="span"
    >
      <CurrencyFormat amount={premiumAmount} />
    </Typography>{' '}
    <Typography display="inline" component="span">
      {PaymentFrequencyText[paymentFrequency]}
    </Typography>
  </Typography>
)
