import Grid2 from '@mui/material/Grid2'
import {
  FormContainer,
  type FormContainerProps,
  TextFieldElement,
} from 'react-hook-form-mui'

import type { FormFields } from './schema'

import { SubmitButton } from '@/common/components/shared/SubmitButton'

export type FormProps = FormContainerProps<FormFields>

export const Form: React.FC<FormProps> = (props) => {
  return (
    <FormContainer {...props}>
      <Grid2 container spacing={3} mb={3}>
        <Grid2 size={12}>
          <TextFieldElement
            name="firstName"
            label="First Name"
            required
            fullWidth
          />
        </Grid2>

        <Grid2 size={12}>
          <TextFieldElement name="middleName" label="Middle Name" fullWidth />
        </Grid2>

        <Grid2 size={10}>
          <TextFieldElement
            name="lastName"
            label="Last Name"
            required
            fullWidth
          />
        </Grid2>

        <Grid2 size={2}>
          <TextFieldElement name="suffix" label="Suffix" fullWidth />
        </Grid2>

        <Grid2 size={12}>
          <TextFieldElement name="email" label="Email" required fullWidth />
        </Grid2>
      </Grid2>

      <SubmitButton>Next</SubmitButton>
    </FormContainer>
  )
}
