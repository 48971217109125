import { useContext, useEffect } from 'react'

import { Context } from './context'
import type { StepEnum } from './types'

export const useProgress = (nextStep?: StepEnum): StepEnum => {
  const context = useContext(Context)

  const { step, setStep } = context

  useEffect(() => {
    if (nextStep != null) {
      setStep(nextStep)
    }
  }, [nextStep, setStep])

  return step
}
