import CssBaseline from '@mui/material/CssBaseline'

import { Container } from './Container'
import { Main } from './Main'

export const ErrorPage: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <>
      <CssBaseline />
      <Container>
        <Main>{children}</Main>
      </Container>
    </>
  )
}
