import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import { BaseError } from '../BaseError'

import Umbrella from './umbrella.png'

const Header = () => (
  <Stack direction="row" alignItems="center" spacing={2}>
    <Typography fontSize={240} fontWeight={800} color="primary">
      4
    </Typography>
    <Box width={200} height={200}>
      <img src={Umbrella} height={200} width={200} />
    </Box>
    <Typography fontSize={240} fontWeight={800} color="primary">
      4
    </Typography>
  </Stack>
)

const Action = () => (
  <Button
    size="large"
    variant="contained"
    disableElevation
    sx={{ px: 3 }}
    onClick={() => {
      window.location.href = '/'
      return false
    }}
  >
    Home
  </Button>
)

const Message = () => (
  <Typography p={4}>The page you are looking for cannot be found.</Typography>
)

export const NotFound: React.FC = () => {
  return (
    <BaseError status={404} header={<Header />}>
      <Message />
      <Action />
    </BaseError>
  )
}
