import Box from '@mui/material/Box'

import { LoadingIndicator } from '../shared/LoadingIndicator'

import { useSession } from '@/common/hooks/useSession'

export const SessionProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const session = useSession()

  if (session.loading) {
    return (
      <Box
        display="flex"
        height="100vh"
        alignItems="center"
        justifyContent="center"
      >
        <LoadingIndicator />
      </Box>
    )
  }

  return children
}
