import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { BaseError } from '../BaseError'

import ErrorImage from './error.png'

const Header = () => (
  <Box>
    <img src={ErrorImage} height={480} />
  </Box>
)

const Action = () => (
  <Button
    size="large"
    variant="contained"
    disableElevation
    sx={{ px: 3 }}
    onClick={() => {
      window.location.reload()
      return false
    }}
  >
    Reload Page
  </Button>
)

const Message = () => (
  <Typography p={4} align="center">
    We&apos;re experiencing a temporary issue.
    <br />
    Please try again later.
    <br />
    Thank you for your patience!
  </Typography>
)

export const InternalServerError: React.FC = () => {
  return (
    <BaseError status={500} header={<Header />}>
      <Message />
      <Action />
    </BaseError>
  )
}
