import { useMutation } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { CreateInsuranceApplication } from './CreateInsuranceApplication'
import type { FormFields } from './Form'
import { Form, Schema } from './Form'

import { useInitialDataFromLocalStorage } from '@/common/hooks/useInitialData'
import type { InsuranceType } from '@/gql/graphql'

export interface GettingStartedProps {
  insuranceType: InsuranceType
}

export const GettingStarted: React.FC<GettingStartedProps> = ({
  insuranceType,
}) => {
  const navigate = useNavigate()
  const [mutation, { error }] = useMutation(CreateInsuranceApplication)
  const [initialData, clearInitialData] = useInitialDataFromLocalStorage()
  const formContext = useForm({
    defaultValues: { ...initialData },
    resolver: yupResolver(Schema),
  })

  if (error != null) {
    throw error
  }

  const handleSubmit = async (fields: FormFields): Promise<void> => {
    const { data, errors } = await mutation({
      variables: {
        insuranceType,
        ...fields,
        agentId: initialData?.agentId,
      },
    })

    if (errors != null || data == null) {
      return
    }

    const { id } = data.createInsuranceApplication

    clearInitialData()

    navigate(id)
  }

  return <Form formContext={formContext} onSuccess={handleSubmit} />
}
