import { useState } from 'react'

import { Context } from './context'
import { StepEnum } from './types'

export const ProgressProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [step, setStep] = useState(StepEnum.QUOTE)
  return (
    <Context.Provider value={{ step, setStep }}>{children}</Context.Provider>
  )
}
