import { ApolloProvider } from '@apollo/client'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { withProfiler, wrapCreateBrowserRouter } from '@sentry/react'
import themes from '@think-life/mui-theme'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react'
import Session from 'supertokens-auth-react/recipe/session'

import { SessionProvider } from './common/components/SessionProvider/SessionProvider'
import { client } from './lib/gqlClient'
import { routes } from './routes'

SuperTokens.init({
  appInfo: {
    appName: import.meta.env.VITE_ST_APP_NAME,
    apiDomain: import.meta.env.VITE_ST_API_DOMAIN,
    websiteDomain: import.meta.env.VITE_ST_WEBSITE_DOMAIN,
    apiBasePath: import.meta.env.VITE_ST_API_BASE_PATH,
    websiteBasePath: import.meta.env.VITE_ST_WEBSITE_BASE_PATH,
  },
  recipeList: [
    Session.init({
      sessionTokenFrontendDomain: import.meta.env.VITE_ST_COOKIE_DOMAIN,
      sessionTokenBackendDomain: import.meta.env.VITE_ST_COOKIE_DOMAIN,
    }),
  ],
})

let router
if ((import.meta.env.VITE_SENTRY_DSN ?? '') !== '') {
  const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)
  router = sentryCreateBrowserRouter(routes)
} else {
  router = createBrowserRouter(routes)
}

export const App: React.FC = withProfiler(() => (
  <SuperTokensWrapper>
    <SessionProvider>
      <CssBaseline />
      <ThemeProvider theme={themes.light}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ApolloProvider client={client}>
            <RouterProvider router={router} />
          </ApolloProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </SessionProvider>
  </SuperTokensWrapper>
))
