import Box from '@mui/material/Box'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import Typography from '@mui/material/Typography'

import { StepConnector } from './StepConnector'
import { STEPS, STEP_SIZE } from './constants'

import { useProgress } from '@/common/hooks/useProgress'

export const ProgressStepper: React.FC = () => {
  const step = useProgress()

  return (
    <Stepper
      activeStep={step}
      alternativeLabel
      sx={{ py: 2 }}
      connector={<StepConnector />}
    >
      {STEPS.map(({ label, icon: Icon }) => (
        <Step key={label}>
          <StepLabel
            sx={{
              '.MuiStepLabel-labelContainer .Mui-completed': {
                color: 'primary.main',
              },
              '.MuiStepLabel-labelContainer .Mui-active': {
                color: 'primary.main',
              },
            }}
            StepIconProps={{
              sx: {
                width: STEP_SIZE,
                height: STEP_SIZE,
              },
            }}
          >
            <Box display="inline-flex" alignItems="flex-end">
              <Icon color="inherit" fontSize="small" sx={{ mr: 0.5 }} />
              <Typography color="inherit">{label}</Typography>
            </Box>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
