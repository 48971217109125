import Typography from '@mui/material/Typography'

import type { Policy } from './types'

import { CurrencyFormat } from '@/common/components/shared/CurrencyFormat'
import {
  IncomeProtectionCoverageType,
  TermLifeCoverageType,
} from '@/gql/graphql'

export interface BenefitAmountProps {
  policy: Policy
}

export const BenefitAmount: React.FC<BenefitAmountProps> = ({ policy }) => {
  const applicant = policy.product.applicant

  let amount: number | undefined
  let unit: string | undefined

  if (applicant?.__typename === 'IncomeProtectionApplicant') {
    const baseCoverage = applicant.incomeProtectionCoverages.find(
      ({ coverageType }) => coverageType === IncomeProtectionCoverageType.Base,
    )

    amount = baseCoverage?.benefitAmount ?? undefined
    unit = 'week'
  } else if (applicant?.__typename === 'TermLifeApplicant') {
    const baseCoverage = applicant.termLifeCoverages.find(
      ({ coverageType }) => coverageType === TermLifeCoverageType.Base,
    )

    amount = baseCoverage?.benefitAmount ?? undefined
  }

  if (amount == null) {
    return
  }

  return (
    <Typography component="span" variant="h4" fontWeight={400}>
      <CurrencyFormat amount={amount} fractionDigits={0} />
      {unit != null ? ` / ${unit}` : ''}
    </Typography>
  )
}
