import Box, { type BoxProps } from '@mui/material/Box'

import Logo from './logo.svg?react'

export const BrandLogo: React.FC<BoxProps> = (props) => {
  return (
    <Box {...props}>
      <Logo width="100%" height="100%" />
    </Box>
  )
}
