import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useState } from 'react'
import { Navigate, Outlet, useMatches } from 'react-router-dom'

import { Main } from './Main'
import { SideBar } from './SideBar'
import { TopNav } from './TopNav'

import { useNavigationGuard } from '@/common/hooks/useNavigationGuard'
import { ProgressProvider } from '@/common/hooks/useProgress'

type LayoutVariant = 'default' | 'docusign'

interface ProductLayoutHandle {
  layout: {
    variant: LayoutVariant
  }
}

function isProductLayoutHandle(handle: unknown): handle is ProductLayoutHandle {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return (handle as ProductLayoutHandle)?.layout?.variant !== undefined
}

export interface ProductLayoutProps {
  sidebarSlot?: React.ReactNode
  footerSlot?: React.ReactNode
}

export const ProductLayout: React.FC<ProductLayoutProps> = ({
  sidebarSlot,
  footerSlot,
}) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const [open, setOpen] = useState(!mdDown)

  const matches = useMatches()
  const isDocusign = matches.some(
    ({ handle }) =>
      isProductLayoutHandle(handle) && handle.layout.variant === 'docusign',
  )

  const { guard, redirectPath } = useNavigationGuard()

  if (guard === 'denied' && redirectPath != null) {
    return <Navigate to={redirectPath} />
  }

  return (
    <ProgressProvider>
      <SideBar
        open={open}
        onClose={() => {
          setOpen(false)
        }}
      >
        {sidebarSlot}
      </SideBar>
      {isDocusign ? (
        <Main sx={{ alignItems: 'center', justifyContent: 'center' }}>
          {guard !== 'loading' && <Outlet />}
        </Main>
      ) : (
        <Main sx={{ px: { md: 3 } }}>
          <TopNav
            onToggleSidebar={() => {
              setOpen(!open)
            }}
          />
          <Container
            fixed
            disableGutters
            maxWidth="md"
            sx={{
              flexGrow: 1,
              pb: { xs: 4, sm: 4, md: 8 },
              px: { xs: 2, sm: 2, md: 0 },
            }}
          >
            <Outlet />
          </Container>
          {footerSlot != null && (
            <Box px={{ xs: 2, sm: 2, md: 0 }}>{footerSlot}</Box>
          )}
        </Main>
      )}
    </ProgressProvider>
  )
}
