import type { RouteObject } from 'react-router-dom'

import { incomeProtectionRoute } from './IncomeProtection'
import { termLifeRoute } from './TermLife'

import { AppLayout } from '@/common/layouts'
import { DashboardLayout } from '@/common/layouts/DashboardLayout'
import { Dashboard } from '@/pages/Dashboard/Dashboard'
import ErrorPage from '@/pages/Errors'
import Root from '@/pages/Root'

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <AppLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Root />,
      },
      {
        path: 'dashboard',
        element: <DashboardLayout />,
        children: [
          {
            index: true,
            element: <Dashboard />,
          },
        ],
      },
      termLifeRoute,
      incomeProtectionRoute,
    ],
  },
]
