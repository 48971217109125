import ChatIcon from '@mui/icons-material/ChatOutlined'
import EmailIcon from '@mui/icons-material/Email'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import CommunicationIcon from './communication.svg?react'
import gradient from './gradient.png'

export const NeedHelpSection = () => (
  <Paper
    sx={{
      borderWidth: 0,
      width: '100%',
      padding: 3,
      backgroundColor: `linear-gradient(180deg, #FAF9FF 0%, #FFFFFF 100%)`,
      backgroundImage: `url(${gradient})`,
      backgroundSize: 'cover',
      borderRadius: 2,
      boxShadow:
        '0px -3px 6px -2px rgba(103, 101, 106, 0.02), 0px 10px 15px -3px rgba(49, 48, 51, 0.05)',
    }}
  >
    <Stack direction="column" alignItems="center" spacing={3}>
      <Box>
        <CommunicationIcon width={128} />

        <Typography variant="h2" fontWeight={700} align="center">
          Need Help?
        </Typography>
      </Box>

      <Box>
        <Stack direction="row" alignItems="center">
          <Avatar
            color="primary"
            sx={{
              marginY: 1,
              width: 36,
              height: 36,
              color: 'white',
              background:
                'linear-gradient(154.98deg, #845FEF 13.57%, #5F42EF 82.77%)',
            }}
          >
            <LocalPhoneIcon color="inherit" fontSize="small" />
          </Avatar>
          <Typography variant="h5" margin={1}>
            (800) 227-0031
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center">
          <Avatar
            color="primary"
            sx={{
              marginY: 1,
              width: 36,
              height: 36,
              color: 'white',
              background:
                'linear-gradient(154.98deg, #845FEF 13.57%, #5F42EF 82.77%)',
            }}
          >
            <EmailIcon color="inherit" fontSize="small" />
          </Avatar>
          <Typography variant="h5" margin={1}>
            info@thinklife.com
          </Typography>
        </Stack>
      </Box>

      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        endIcon={<ChatIcon />}
        sx={{
          '&.MuiButton-containedPrimary': {
            background:
              'linear-gradient(121.57deg, #845FEF 1.46%, #5F42EF 94.5%)',
          },
        }}
      >
        Chat
      </Button>
    </Stack>
  </Paper>
)
