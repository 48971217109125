import { Button } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import { Link } from 'react-router-dom'

import { BenefitAmount } from './BenefitAmount'
import { BenefitPeriod } from './BenefitPeriod'
import { PolicyLogo } from './Logo'
import { PolicyNumber } from './PolicyNumber'
import { PolicyTitle } from './Title'
import type { Policy } from './types'

import { InsuranceType } from '@/gql/graphql'

const UriPrefix = {
  [InsuranceType.TermLife]: '/life',
  [InsuranceType.IncomeProtection]: '/disability',
}

export interface PolicyProps {
  status: 'approved' | 'pending' | 'declined' | 'incomplete'
  policy: Policy
}

export const PolicyCard: React.FC<PolicyProps> = ({ policy, status }) => {
  const {
    type,
    product: { underwritingDecisions },
  } = policy
  const policyNumber = underwritingDecisions?.policyNumber ?? undefined

  const path = `${UriPrefix[type]}/${policy.id}`

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack direction="row" spacing={3}>
          <PolicyLogo type={type} />

          <Stack sx={{ flex: 1 }}>
            <Stack direction="row" spacing={1}>
              <PolicyTitle type={type} />
              <PolicyNumber policyNumber={policyNumber} />
            </Stack>
            <BenefitAmount policy={policy} />
            <BenefitPeriod policy={policy} />
          </Stack>

          {status === 'incomplete' && (
            <Button component={Link} to={path}>
              Continue
            </Button>
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}
