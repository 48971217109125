export const ThinkLifeIcon: React.FC = () => {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9356 0.973094H11.1989V0H19.8011V0.973094H16.0616V14.1404H14.9356V0.973094ZM4.82933 5.62448L2.18529 8.26852L1.49856 7.58179L7.58179 1.49856L8.26852 2.18529L5.62448 4.82933L14.9356 14.1404L14.1404 14.9356L4.82933 5.62448ZM0.973094 16.0616V19.8011H0V11.1989H0.973094V14.9356H14.1404V16.0616H0.973094ZM5.62448 26.1679L8.26852 28.8119L7.58179 29.5014L1.49856 23.4182L2.18529 22.7287L4.82933 25.3727L14.1404 16.0616L14.9356 16.8568L5.62448 26.1679ZM16.0616 30.0269H19.8011V31H11.1989V30.0269H14.9356V16.8568H16.0616V30.0269ZM26.1679 25.3727L28.8119 22.7287L29.5014 23.4182L23.4182 29.5014L22.7287 28.8119L25.3727 26.1679L16.0616 16.8568L16.8568 16.0616L26.1679 25.3727ZM30.0269 14.9356V11.1989H31V19.8011H30.0269V16.0616H16.8568V14.9356H30.0269ZM25.3727 4.82933L22.7287 2.18529L23.4182 1.49856L29.5014 7.58179L28.8119 8.26852L26.1679 5.62448L16.8568 14.9356L16.0616 14.1404L25.3727 4.82933Z"
        fill="url(#paint0_linear_595_3470)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_595_3470"
          x1="0"
          y1="15.5"
          x2="31"
          y2="15.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AF94F5" />
          <stop offset="1" stopColor="#9582FC" />
        </linearGradient>
      </defs>
    </svg>
  )
}
