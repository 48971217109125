export interface CurrencyFormatProps {
  locale?: string
  currency?: string
  style?: keyof Intl.NumberFormatOptionsStyleRegistry
  amount: number
  fractionDigits?: number
}

export const CurrencyFormat: React.FC<CurrencyFormatProps> = ({
  locale = 'en-US',
  currency = 'USD',
  style = 'currency',
  fractionDigits = 2,
  amount,
}) => {
  const formatter = new Intl.NumberFormat(locale, {
    style,
    currency,
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  })

  return formatter.format(amount)
}
