import Box from '@mui/material/Box'

import { IncomeProtectionIcon } from '@/common/components/shared/IncomeProtectionIcon'
import { TermLifeIcon } from '@/common/components/shared/TermLifeIcon'
import { InsuranceType } from '@/gql/graphql'

const IconLookup = {
  [InsuranceType.IncomeProtection]: <IncomeProtectionIcon />,
  [InsuranceType.TermLife]: <TermLifeIcon />,
} as const

export interface PolicyLogoProps {
  type: InsuranceType
  width?: number
  height?: number
}

export const PolicyLogo: React.FC<PolicyLogoProps> = ({
  type,
  width = 64,
  height = 64,
}) => {
  return (
    <Box width={width} height={height}>
      {IconLookup[type]}
    </Box>
  )
}
