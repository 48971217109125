import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'

export interface LoadingIndicatorProps {
  fullscreen?: boolean
}

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  fullscreen = false,
}) => {
  if (fullscreen) {
    return (
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open
      >
        <CircularProgress />
      </Backdrop>
    )
  }

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      padding={4}
    >
      <CircularProgress />
    </Stack>
  )
}
