import { graphql } from '@/gql'

export const TermLifeAcceptedQuote = graphql(`
  query TermLifeAcceptedQuote($appId: ID!) {
    insuranceApplication(id: $appId) {
      id
      product {
        applicant {
          id
          ... on TermLifeApplicant {
            coverages {
              benefitAmount
              coverageType
              id
              termPeriod
            }
          }
        }
        id
        ... on TermLifeProduct {
          quotes {
            coverageType
            id
            paymentFrequency
            premiumAmount
          }
        }
      }
    }
  }
`)
