import Card, { type CardProps } from '@mui/material/Card'

import { CardContent } from './CardContent'
import { CardHeader } from './CardHeader'
import type { TermLifeQuotePremium } from './types'

import {
  TermLifeCoverageType,
  type TermLifeQuoteCoverageInput,
} from '@/gql/graphql'

export interface QuoteCardProps {
  title?: string
  loading?: boolean
  baseCoverage: TermLifeQuoteCoverageInput
  totalPremium?: TermLifeQuotePremium
  cardProps?: CardProps
  children?: React.ReactNode
}

export const QuoteCard: React.FC<QuoteCardProps> = ({
  loading = false,
  baseCoverage,
  totalPremium,
  cardProps,
  children,
  title,
}) => {
  const { termPeriod, benefitAmount, coverageType } = baseCoverage
  if (coverageType !== TermLifeCoverageType.Base) {
    throw new Error('Base coverage type is not BASE')
  }

  if (termPeriod == null) {
    throw new Error('base coverage term period must be specified.')
  }

  if (benefitAmount == null) {
    throw new Error('base coverage benefit amount must be specified.')
  }

  return (
    <Card {...cardProps}>
      {title != null && <CardHeader title={title} />}

      <CardContent
        termPeriod={termPeriod}
        benefitAmount={benefitAmount}
        loading={loading}
        premium={totalPremium}
      />

      {children}
    </Card>
  )
}
