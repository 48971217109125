import { isApolloError } from '@apollo/client'
import Typography from '@mui/material/Typography'
import { captureException } from '@sentry/react'
import { useEffect } from 'react'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'

import { BaseError } from './Error/BaseError'
import { InternalServerError } from './Error/InternalServerError'
import { NotFound } from './Error/NotFound'
import { ErrorPage } from './Page'

function hasToString(value: unknown): value is { toString: () => string } {
  return typeof value === 'object' && value !== null && 'toString' in value
}

interface ErrorInfo {
  status: number
  message: string
}

const categorizeError = (error: unknown): ErrorInfo => {
  if (isRouteErrorResponse(error)) {
    return {
      status: error.status,
      message: (error.data as string | undefined) ?? error.statusText,
    }
  }

  if (error instanceof Error && isApolloError(error)) {
    const extensions = error.cause?.extensions as
      | Record<string, unknown>
      | undefined

    switch (extensions?.code as string | undefined | null) {
      case 'FORBIDDEN':
        return {
          status: 404,
          message: error.message,
        }
      default:
        return {
          status: 500,
          message: error.message,
        }
    }
  }

  if (error instanceof Error) {
    return {
      status: 500,
      message: error.message,
    }
  }

  return {
    status: 500,
    message: hasToString(error) ? error.toString() : 'Unknown error',
  }
}

const renderError = (error: unknown) => {
  const { status, message } = categorizeError(error)

  switch (status) {
    case 403:
    case 404:
      return <NotFound />
    case 500:
      return <InternalServerError />
    default:
      return (
        <BaseError status={status}>
          <Typography p={4}>{message}</Typography>
        </BaseError>
      )
  }
}

const Page: React.FC = () => {
  const error = useRouteError()

  useEffect(() => {
    captureException(error)
    console.error(error)
  }, [error])

  return <ErrorPage>{renderError(error)}</ErrorPage>
}

export default Page
