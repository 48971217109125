import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import { lighten, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Link } from 'react-router-dom'

import { NeedHelpSection } from './NeedHelpSection'

import { BrandLogo } from '@/common/components/shared/BrandLogo'

const DRAWER_WIDTH = 260

export type SideBarProps = React.PropsWithChildren<{
  open: boolean
  onClose: () => void
}>

export const SideBar: React.FC<SideBarProps> = ({
  open,
  onClose,
  children,
}) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const bgcolor = lighten(theme.palette.primary.main, 0.95)

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: { xs: 'auto', md: DRAWER_WIDTH } }}
    >
      <Drawer
        variant={mdDown ? `temporary` : 'permanent'}
        anchor="left"
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: DRAWER_WIDTH,
            boxSizing: 'border-box',
            backgroundColor: bgcolor,
            borderRightWidth: 1,
            alignItems: 'center',
          },
        }}
        ModalProps={{ keepMounted: true }}
      >
        <Box mb={3}>
          <Link to="/">
            <Box width={150} mt={3}>
              <BrandLogo />
            </Box>
          </Link>
        </Box>

        {children != null && (
          <Box width="100%" mb={3} mt={6} px={3}>
            {children}
          </Box>
        )}

        <Box flexGrow={1} />

        <Box width="100%" mb={3} px={3}>
          <NeedHelpSection />
        </Box>
      </Drawer>
    </Box>
  )
}
