import { GettingStarted } from '@/common/components/GettingStarted'
import { PageHeading } from '@/common/components/shared/PageHeading'
import { StepEnum, useProgress } from '@/common/hooks/useProgress'
import { InsuranceType } from '@/gql/graphql'

export const Root: React.FC = () => {
  useProgress(StepEnum.QUOTE)

  return (
    <>
      <PageHeading>
        Please enter your basic information to get started with a quote
      </PageHeading>

      <GettingStarted insuranceType={InsuranceType.TermLife} />
    </>
  )
}
