import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

export type BaseErrorProps = React.PropsWithChildren<{
  /**
   * The header to render
   * @default null
   */
  header?: React.ReactNode

  /**
   * The error status code.
   */
  status: number
}>

export const BaseError: React.FC<BaseErrorProps> = ({
  header,
  status,
  children,
}) => {
  return (
    <>
      {header}

      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="h3" fontWeight={600}>
          ERROR
        </Typography>
        <Typography variant="h3" fontWeight={600} color="primary">
          {status}
        </Typography>
      </Stack>

      {children}
    </>
  )
}
