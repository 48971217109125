import { useContext, useEffect, useState } from 'react'
// eslint thinks react-cookie is not installed but it is...
// eslint-disable-next-line import/no-unresolved
import { useCookies } from 'react-cookie'
import {
  type ClaimValidationError,
  SessionContext,
} from 'supertokens-auth-react/recipe/session'

type CreateGuestSessionStatus =
  | 'GUEST_SESSION_CREATED'
  | 'GUEST_SESSION_ALREADY_EXISTS'
  | 'USER_SESSION_ALREADY_EXISTS'

interface CreateGuestSessionResult {
  status: CreateGuestSessionStatus
}

async function createGuestSession(): Promise<CreateGuestSessionResult> {
  const url = `${import.meta.env.VITE_ST_API_DOMAIN}/api/guest-session`
  const response = await fetch(url)
  if (response.status !== 200) {
    throw new Error('Unable to create guest session.')
  }

  return (await response.json()) as CreateGuestSessionResult
}

export type Session =
  | {
      loading: true
    }
  | {
      loading: false
      hasError: true
      error: Error | ClaimValidationError
    }
  | {
      loading: false
      hasError: false
      hasUserSession: true
      hasGuestSession: false
      userId: string
    }
  | {
      loading: false
      hasError: false
      hasUserSession: false
      hasGuestSession: true
      guestSessionStatus: CreateGuestSessionStatus
    }

export const useSession = (): Session => {
  const [cookies] = useCookies<
    'guest-session-expires-at',
    { 'guest-session-expires-at'?: string }
  >(['guest-session-expires-at'])
  const userSession = useContext(SessionContext)
  const [fetched, setFetched] = useState(false)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error>()
  const [status, setStatus] = useState<CreateGuestSessionStatus>()

  useEffect(() => {
    if (fetched) return
    if (userSession.loading) return

    // access denied
    if (userSession.accessDeniedValidatorError != null) {
      setLoading(false)
      setFetched(true)
      return
    }

    // user session already exists
    if (userSession.doesSessionExist) {
      setLoading(false)
      setFetched(true)
      setStatus('USER_SESSION_ALREADY_EXISTS')
      return
    }

    // guest session already exists and is not expired
    if (
      cookies['guest-session-expires-at'] != null &&
      new Date(+cookies['guest-session-expires-at']) > new Date()
    ) {
      setLoading(false)
      setFetched(true)
      setStatus('GUEST_SESSION_ALREADY_EXISTS')
      return
    }

    createGuestSession()
      .then(({ status }) => {
        setStatus(status)
      })
      .catch(setError)
      .finally(() => {
        setLoading(false)
      })

    setFetched(true)
  }, [cookies, fetched, userSession])

  if (userSession.loading) {
    return { loading: true }
  }

  if (userSession.accessDeniedValidatorError != null) {
    return {
      loading: false,
      hasError: true,
      error: userSession.accessDeniedValidatorError,
    }
  }

  if (userSession.doesSessionExist) {
    return {
      loading: false,
      hasError: false,
      hasUserSession: true,
      hasGuestSession: false,
      userId: userSession.userId,
    }
  }

  if (loading) {
    return { loading: true }
  }

  if (error != null) {
    return { loading: false, hasError: true, error }
  }

  return {
    loading: false,
    hasError: false,
    hasUserSession: false,
    hasGuestSession: true,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    guestSessionStatus: status!,
  }
}
