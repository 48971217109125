import { Navigate, type RouteObject } from 'react-router-dom'

import { AcceptedQuoteCard } from '@/common/components/TermLife/AcceptedQuoteCard'
import { LegalDisclaimer } from '@/common/components/TermLife/LegalDisclaimer'
import { ProductLayout } from '@/common/layouts'
import { InsuranceApplicationState } from '@/gql/graphql'
import { Root } from '@/pages/TermLife/Root'

export const termLifeRoute: RouteObject = {
  path: 'life',
  element: (
    <ProductLayout
      footerSlot={<LegalDisclaimer />}
      sidebarSlot={<AcceptedQuoteCard />}
    />
  ),
  children: [
    {
      index: true,
      element: <Root />,
    },
    {
      path: ':appId',
      children: [
        {
          index: true,
          element: <Navigate to="basic-info" relative="path" />,
        },
        {
          path: 'basic-info',
          lazy: async () => await import('@/pages/TermLife/BasicInfo'),
          handle: { allowedStates: [InsuranceApplicationState.Initial] },
        },
        {
          path: 'quotes',
          lazy: async () => await import('@/pages/TermLife/Quotes'),
          handle: { allowedStates: [InsuranceApplicationState.Initial] },
        },
        {
          path: 'contact-info',
          lazy: async () => await import('@/pages/TermLife/ContactInfo'),
          handle: { allowedStates: [InsuranceApplicationState.Initial] },
        },
        {
          path: 'legal-detail',
          lazy: async () => await import('@/pages/TermLife/LegalDetail'),
          handle: { allowedStates: [InsuranceApplicationState.Initial] },
        },
        {
          path: 'health-info',
          lazy: async () => await import('@/pages/TermLife/HealthInfo'),
          handle: { allowedStates: [InsuranceApplicationState.Initial] },
        },
        {
          path: 'employment-info',
          lazy: async () => await import('@/pages/TermLife/EmploymentInfo'),
          handle: { allowedStates: [InsuranceApplicationState.Initial] },
        },
        {
          path: 'coverage-info',
          lazy: async () => await import('@/pages/TermLife/CoverageInfo'),
          handle: { allowedStates: [InsuranceApplicationState.Initial] },
        },
        {
          path: 'permission-notice',
          lazy: async () =>
            await import('@/common/components/PermissionNotice'),
          handle: { allowedStates: [InsuranceApplicationState.Initial] },
        },
        {
          path: 'interview-questions',
          children: [
            {
              index: true,
              lazy: async () =>
                await import('@/pages/TermLife/InterviewQuestions'),
            },
            {
              path: ':questionId',
              lazy: async () =>
                await import('@/pages/TermLife/InterviewQuestions'),
            },
          ],
          handle: { allowedStates: [InsuranceApplicationState.Submitted] },
        },
        {
          path: 'primary-physician',
          lazy: async () => await import('@/pages/TermLife/PrimaryPhysician'),
          handle: { allowedStates: [InsuranceApplicationState.Submitted] },
        },
        {
          path: 'decision',
          lazy: async () => await import('@/pages/TermLife/Decisions'),
          handle: {
            allowedStates: [
              InsuranceApplicationState.Qualified,
              InsuranceApplicationState.Finalized,
            ],
          },
        },
        {
          path: 'beneficiaries',
          lazy: async () => await import('@/common/components/Beneficiaries'),
          handle: {
            allowedStates: [InsuranceApplicationState.Qualified],
          },
        },
        {
          path: 'secondary-addressee',
          lazy: async () => await import('@/pages/TermLife/Payors'),
          handle: {
            allowedStates: [InsuranceApplicationState.Qualified],
          },
        },
        {
          path: 'payment',
          lazy: async () => await import('@/common/components/Payment'),
          handle: {
            allowedStates: [InsuranceApplicationState.Qualified],
          },
        },
        {
          path: 'documents',
          lazy: async () => await import('@/common/components/Documents'),
          handle: {
            allowedStates: [InsuranceApplicationState.Sealed],
            layout: { variant: 'docusign' },
          },
        },
        {
          path: 'complete',
          lazy: async () => await import('@/common/components/Complete'),
          handle: {
            allowedStates: [
              InsuranceApplicationState.Signed,
              InsuranceApplicationState.Finalized,
            ],
          },
        },
      ],
    },
  ],
}
