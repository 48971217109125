import Box, { type BoxProps } from '@mui/material/Box'

import Icon from './income-protection.svg?react'

export const IncomeProtectionIcon: React.FC<BoxProps> = (props) => {
  return (
    <Box {...props}>
      <Icon width="100%" height="100%" />
    </Box>
  )
}
