import Typography from '@mui/material/Typography'

export const PageHeading: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <Typography variant="h2" paragraph gutterBottom mb={5}>
      {children}
    </Typography>
  )
}
