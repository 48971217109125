import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { CurrencyFormat } from '@/common/components/shared/CurrencyFormat'

export interface BenefitAmountSectionProps {
  benefitAmount: number
}

export const BenefitAmountSection: React.FC<BenefitAmountSectionProps> = ({
  benefitAmount,
}) => {
  return (
    <Box>
      <Typography fontWeight={600} variant="body2" gutterBottom>
        Coverage Amount
      </Typography>
      <Typography variant="h3" fontWeight={700}>
        <CurrencyFormat amount={benefitAmount} fractionDigits={0} />
      </Typography>
    </Box>
  )
}
