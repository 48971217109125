import type { RouteObject } from 'react-router-dom'

export const route: RouteObject = {
  path: 'employee-occupations',
  children: [
    {
      index: true,
      lazy: async () => await import('./Root'),
    },
    {
      path: 'new',
      lazy: async () => await import('./New'),
    },
    {
      path: ':id/edit',
      lazy: async () => await import('./Edit'),
    },
  ],
}
