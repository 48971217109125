import { Navigate } from 'react-router-dom'

import { Products } from './Products'

import { useInitialDataFromSearchParams } from '@/common/hooks/useInitialData'
import { InsuranceType } from '@/gql/graphql'

const PRODUCT_PATHS = {
  [InsuranceType.IncomeProtection]: 'disability',
  [InsuranceType.TermLife]: 'life',
}

const Root: React.FC = () => {
  const initialData = useInitialDataFromSearchParams()
  if (initialData != null) {
    return <Navigate to={`/${PRODUCT_PATHS[initialData.policyType]}`} />
  }

  return <Products />
}

export default Root
