import { graphql } from '@/gql'

export const ApplicationState = graphql(`
  query ApplicationState($id: ID!) {
    insuranceApplication(id: $id) {
      id
      ... on InsuranceApplication {
        state
        type
      }
    }
  }
`)
