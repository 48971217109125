import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'

export interface LimitationsAndExclusionsDialogProps {
  open: boolean
  handleClose: () => void
}

export const LimitationsAndExclusionsDialog: React.FC<
  LimitationsAndExclusionsDialogProps
> = ({ open, handleClose }) => (
  <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
    <DialogTitle>Limitations and Exclusions</DialogTitle>

    <DialogContent dividers>
      <Typography gutterBottom sx={{ mb: 2 }} variant="h3">
        Standard Policy Limitations and Exclusions (Form No. I H2016)
      </Typography>

      <Typography gutterBottom sx={{ mb: 2 }}>
        <b>Note:</b> Limitations and Exclusions may vary by state. The state
        specific policy form is the ultimate authority for any questions about
        this product.
      </Typography>

      <Typography gutterBottom sx={{ mb: 2 }}>
        <u>Pre-existing Condition</u> - If disability is within two years from
        the issue date and is due to a pre-existing condition, no benefits will
        be paid unless the condition was disclosed and not misrepresented on the
        application and is not excluded by a policy amendment rider.
      </Typography>

      <Typography gutterBottom sx={{ mb: 2 }}>
        <u>Elimination Period</u> - This policy includes an elimination period
        which is the amount of time the insured must be continuously, totally
        disabled before eligible to receive weekly benefits.
      </Typography>

      <Typography gutterBottom sx={{ mb: 2 }}>
        <u>Mental/Nervous Disorders</u> - A maximum of 104 disability weekly
        benefits will be paid during the insured’s lifetime for disabilities due
        to mental/nervous disorders.
      </Typography>

      <Typography gutterBottom sx={{ mb: 2 }}>
        <u>Substance Abuse</u> - A maximum of 104 disability weekly benefits
        will be paid during the insured’s lifetime for disabilities due to
        substance abuse.
      </Typography>

      <Typography gutterBottom sx={{ mb: 2 }}>
        <u>Guaranteed Renewable</u> - This policy form is guaranteed renewable,
        meaning the policy owner may continue the coverage provided through the
        end of the renewal period by payment of the required premiums when they
        are due. Terms of the policy cannot be changed while it is in force.
        Premiums for the policy may change, but any changes require prior
        regulatory approval and can be done on an insured class basis only.
      </Typography>

      <Typography gutterBottom sx={{ mb: 2 }}>
        <u>Misstatement of Age and/or Gender</u> - If age and/or gender is
        misstated, an adjustment in premiums, coverage, or both, will be made
        based on the correct age and/or gender. If, according to the correct
        age, the coverage provided by this policy would not have become
        effective, or would have ceased, the only liability during the period in
        which the insured was not eligible for coverage, shall be limited to the
        refund of premiums paid for such period.
      </Typography>

      <Typography gutterBottom sx={{ mb: 2 }}>
        <u>Misstatement of Income</u> - If annual income was overstated at the
        time of policy application, an adjustment in both coverage and premiums
        may be made. If, according to the correct income, the coverage provided
        by the policy would not have become effective, liability shall be
        limited to the refund of premiums paid.
      </Typography>

      <Typography gutterBottom sx={{ mb: 2 }}>
        <u>Misstatement of Tobacco Use</u> - If, during the first two years of
        this policy, the tobacco status has been misstated, an adjustment in
        policy and rider benefit amounts will be made based on the amount the
        premium paid would have purchased using the correct tobacco status on
        the Issue Date.
      </Typography>

      <Typography gutterBottom sx={{ mb: 2 }}>
        <u>Foreign Travel and Residency</u> - A maximum of 52 disability weekly
        benefits will be paid for any disability sustained while residing
        outside the United States, its territories or possessions, or Canada.
      </Typography>

      <Typography gutterBottom sx={{ mb: 2 }}>
        <u>Military Service</u> - The policy may be suspended if the insured
        enters active military service.
      </Typography>

      <Typography gutterBottom sx={{ mb: 2 }}>
        Benefits will not be paid for conditions that are caused by or the
        result of the insured:
      </Typography>
      <ul>
        <li>
          <Typography gutterBottom sx={{ mb: 2 }}>
            being pregnant or having an elective abortion (as applies to
            Accident and Sickness policy type) (complication of pregnancy is
            deemed to be a sickness);
          </Typography>
        </li>
        <li>
          <Typography gutterBottom sx={{ mb: 2 }}>
            war or any act of war, declared or undeclared;
          </Typography>
        </li>
        <li>
          <Typography gutterBottom sx={{ mb: 2 }}>
            actively serving in any of the armed forces or units auxiliary
            thereto, including the National Guard or Reserve, except during the
            active duty training of less than 60 days;
          </Typography>
        </li>
        <li>
          <Typography gutterBottom sx={{ mb: 2 }}>
            committing or attempting to commit a felony;
          </Typography>
        </li>
        <li>
          <Typography gutterBottom sx={{ mb: 2 }}>
            being legally incarcerated in a penal or correctional institution
            for more than seven days or during a period of legal detainment of
            more than seven days where the period of legal incarceration or
            legal detainment results in the insured person’s inability to meet
            any work requirements in the definition of total disability;
          </Typography>
        </li>
        <li>
          <Typography gutterBottom sx={{ mb: 2 }}>
            participating in a riot, insurrection or rebellion;
          </Typography>
        </li>
        <li>
          <Typography gutterBottom sx={{ mb: 2 }}>
            intentionally self-inflicting an injury or attempting to commit
            suicide, while sane or insane;
          </Typography>
        </li>
        <li>
          <Typography gutterBottom sx={{ mb: 2 }}>
            engaging in an illegal occupation;
          </Typography>
        </li>
        <li>
          <Typography gutterBottom sx={{ mb: 2 }}>
            having an illness, disease or infection, other than infection from
            an injury received while this policy is in force (as applies to
            Accident-Only policy type);
          </Typography>
        </li>
        <li>
          <Typography gutterBottom sx={{ mb: 2 }}>
            having a mental and/or nervous disorder, subject to the applicable
            law in the state where this policy was delivered or issued for
            delivery (as applies to Accident-Only policy type);
          </Typography>
        </li>
        <li>
          <Typography gutterBottom sx={{ mb: 2 }}>
            being addicted to drugs or suffering from alcoholism, subject to the
            applicable law in the state where this policy was delivered or
            issued for delivery (as applies to Accident-Only policy type);
          </Typography>
        </li>
        <li>
          <Typography gutterBottom sx={{ mb: 2 }}>
            being under the influence of an illegal substance, excitant,
            depressant, hallucinogen, narcotic, or any other drug or intoxicant,
            unless administered on the advice and as directed by a physician,
            subject to the applicable law in the state where this policy was
            delivered or issued for delivery;
          </Typography>
        </li>
        <li>
          <Typography gutterBottom sx={{ mb: 2 }}>
            being intoxicated (as determined by the laws governing the operation
            of motor vehicles in the state where this policy was delivered or
            issued for delivery); or
          </Typography>
        </li>
        <li>
          <Typography gutterBottom sx={{ mb: 2 }}>
            having cosmetic surgery, except for reconstructive surgery when the
            surgery is incidental to or follows surgery resulting from trauma,
            infection, or other diseases of the involved part, and
            reconstructive surgery because of congenital disease or anomaly
            resulting in a functional defect.
          </Typography>
        </li>
      </ul>

      <Typography gutterBottom sx={{ mb: 2 }} variant="h4">
        Social Insurance Offset Benefit
      </Typography>

      <Typography gutterBottom sx={{ mb: 2 }}>
        Any benefit payments under this rider are subject to the following
        conditions:
      </Typography>

      <ul style={{ listStyle: 'outside', padding: '0 32px 32px 32px' }}>
        <li>
          <Typography gutterBottom sx={{ mb: 2 }}>
            The insured applies for any Social Insurance Benefits for which they
            are eligible in a timely manner;
          </Typography>
        </li>
        <li>
          <Typography gutterBottom sx={{ mb: 2 }}>
            The insured provides written proof they have applied for Social
            Insurance Benefits along with the amount they are qualified to
            receive;
          </Typography>
        </li>
        <li>
          <Typography gutterBottom sx={{ mb: 2 }}>
            The insured provides written permission to obtain information on
            their application, reapplication or appeal for Social Insurance
            Benefits;
          </Typography>
        </li>
        <li>
          <Typography gutterBottom sx={{ mb: 2 }}>
            The insured actively pursues all appeal procedures available in a
            timely manner if denied Social Insurance Benefits;
          </Typography>
        </li>
        <li>
          <Typography gutterBottom sx={{ mb: 2 }}>
            The insured reapplies for Social Insurance Benefits upon request, if
            there is a change in circumstances or in the law; and
          </Typography>
        </li>
        <li>
          <Typography gutterBottom sx={{ mb: 2 }}>
            The insured provides proof of any award or payment of Social
            Insurance Benefits as soon as they are received and notification of
            any change in benefit eligibility or payment status in a timely
            manner.
          </Typography>
        </li>
      </ul>
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={handleClose}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
)
