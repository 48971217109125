import Box from '@mui/material/Box'
import { Outlet } from 'react-router-dom'

import { GoogleTagsManager } from './GoogleTagsManager'

export const AppLayout: React.FC = () => (
  <Box display="flex" flexDirection="column" sx={{ height: '100vh' }}>
    <GoogleTagsManager id={import.meta.env.VITE_GA_MEASUREMENT_ID} />
    <Box display="flex" flex={1}>
      <Outlet />
    </Box>
  </Box>
)
