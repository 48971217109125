import Typography from '@mui/material/Typography'

import {
  IncomeProtectionBenefitPeriod,
  IncomeProtectionEliminationPeriod,
} from '@/gql/graphql'

const BenefitPeriodText = {
  [IncomeProtectionBenefitPeriod.ThirteenWeeks]: '13 Weeks',
  [IncomeProtectionBenefitPeriod.TwentySixWeeks]: '26 Weeks',
  [IncomeProtectionBenefitPeriod.OneYear]: '1 Year',
  [IncomeProtectionBenefitPeriod.TwoYears]: '2 Years',
  [IncomeProtectionBenefitPeriod.FiveYears]: '5 Years',
  [IncomeProtectionBenefitPeriod.TenYears]: '10 Years',
}

const EliminationPeriodText = {
  // accident only
  [IncomeProtectionEliminationPeriod.ZeroAccidentDays]: '0 Accident Days',
  [IncomeProtectionEliminationPeriod.SevenAccidentDays]: '7 Accident Days',
  [IncomeProtectionEliminationPeriod.FourteenAccidentDays]: '14 Accident Days',
  [IncomeProtectionEliminationPeriod.ThirtyAccidentDays]: '30 Accident Days',
  [IncomeProtectionEliminationPeriod.SixtyAccidentDays]: '60 Accident Days',
  [IncomeProtectionEliminationPeriod.NinetyAccidentDays]: '90 Accident Days',

  // accident & sickness
  [IncomeProtectionEliminationPeriod.ZeroAccidentSevenSicknessDays]:
    '0 Accident Days / 7 Sick Days',
  [IncomeProtectionEliminationPeriod.ZeroAccidentFourteenSicknessDays]:
    '0 Accident Days / 14 Sick Days',
  [IncomeProtectionEliminationPeriod.SevenAccidentSevenSicknessDays]:
    '7 Accident Days / 7 Sick Days',
  [IncomeProtectionEliminationPeriod.FourteenAccidentFourteenSicknessDays]:
    '14 Accident Days / 14 Sick Days',
  [IncomeProtectionEliminationPeriod.ThirtyAccidentThirtySicknessDays]:
    '30 Accident Days / 30 Sick Days',
  [IncomeProtectionEliminationPeriod.SixtyAccidentSixtySicknessDays]:
    '60 Accident Days / 60 Sick Days',
  [IncomeProtectionEliminationPeriod.NinetyAccidentNinetySicknessDays]:
    '90 Accident Days / 90 Sick Days',
}

export interface BenefitPeriodProps {
  benefitPeriod: IncomeProtectionBenefitPeriod
  eliminationPeriod: IncomeProtectionEliminationPeriod
}

export const BenefitPeriod: React.FC<BenefitPeriodProps> = ({
  benefitPeriod,
  eliminationPeriod,
}) => {
  return (
    <Typography component="span" variant="h4" fontWeight={400}>
      {BenefitPeriodText[benefitPeriod]}
      {' / '}
      {EliminationPeriodText[eliminationPeriod]}
    </Typography>
  )
}
