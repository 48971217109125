import type { RouteObject } from 'react-router-dom'

import { businessOwnerOccupationRoute } from './BusinessOwnerOccupation'
import { employeeOccupationRoute } from './EmployeeOccupation'
import { rootRoute } from './Root'
import { selfEmployedOccupationRoute } from './SelfEmployedOccupation'
import { unearnedIncomeRoute } from './UnearnedIncome'
import { validateRoute } from './Validate'

import { InsuranceApplicationState } from '@/gql/graphql'

export const route: RouteObject = {
  path: 'income-information',
  children: [
    rootRoute,
    employeeOccupationRoute,
    selfEmployedOccupationRoute,
    businessOwnerOccupationRoute,
    unearnedIncomeRoute,
    validateRoute,
  ],
  handle: { allowedStates: [InsuranceApplicationState.Initial] },
}
