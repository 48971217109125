import { createContext } from 'react'

import { StepEnum } from './types'

export interface ProgressContextValue {
  step: StepEnum
  setStep: (step: StepEnum) => void
}

export const Context = createContext<ProgressContextValue>({
  step: StepEnum.QUOTE,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setStep: () => {},
})
