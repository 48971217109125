import { type InferType, object, string } from 'yup'

export const Schema = object({
  firstName: string().label('First name').max(20).required(),
  lastName: string().label('Last name').max(40).required(),
  middleName: string().label('Middle name').max(20),
  suffix: string().label('Suffix').max(6),
  email: string().label('Email').email().max(100).required(),
})

export type FormFields = InferType<typeof Schema>
