import MuiCardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'

import type { IncomeProtectionQuotePremium } from '../types'

import { BenefitAmountSection } from './BenefitAmountSection'
import { BenefitPeriodSection } from './BenefitPeriodSection'
import { EliminationPeriodSection } from './EliminationPeriodSection'
import { PremiumSection } from './PremiumSection'

import type {
  IncomeProtectionBenefitPeriod,
  IncomeProtectionEliminationPeriod,
} from '@/gql/graphql'

export interface CardContentProps {
  loading: boolean
  benefitPeriod: IncomeProtectionBenefitPeriod
  eliminationPeriod: IncomeProtectionEliminationPeriod
  benefitAmount: number
  premium?: IncomeProtectionQuotePremium
}

export const CardContent: React.FC<CardContentProps> = ({
  loading,
  benefitPeriod,
  eliminationPeriod,
  benefitAmount,
  premium,
}) => (
  <MuiCardContent>
    <Stack spacing={2}>
      <BenefitPeriodSection benefitPeriod={benefitPeriod} />
      <EliminationPeriodSection eliminationPeriod={eliminationPeriod} />

      <Divider />

      <BenefitAmountSection benefitAmount={benefitAmount} />

      <Divider />

      <PremiumSection loading={loading} premium={premium} />
    </Stack>
  </MuiCardContent>
)
