import Typography from '@mui/material/Typography'

import { InsuranceType } from '@/gql/graphql'

const Title = {
  [InsuranceType.IncomeProtection]: 'Disability',
  [InsuranceType.TermLife]: 'Life',
} as const

export interface PolicyTitleProps {
  type: InsuranceType
}

export const PolicyTitle: React.FC<PolicyTitleProps> = ({ type }) => {
  return (
    <Typography variant="h4" textTransform="uppercase">
      {Title[type]}
    </Typography>
  )
}
