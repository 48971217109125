import { Container } from '@mui/material'
import Box from '@mui/material/Box'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useSessionContext } from 'supertokens-auth-react/recipe/session'

import { TopNav } from './TopNav'

import { LoadingIndicator } from '@/common/components/shared/LoadingIndicator'

interface Payload {
  fn?: string
  ln?: string
  av?: string
}

export const DashboardLayout: React.FC = () => {
  const userSession = useSessionContext()
  const payload =
    !userSession.loading && userSession.accessDeniedValidatorError == null
      ? (userSession.accessTokenPayload as Payload)
      : null
  const { fn: firstName, ln: lastName, av: avatarUrl } = payload ?? {}
  const profile = { firstName, lastName, avatarUrl }

  useEffect(() => {
    if (!userSession.loading && !userSession.doesSessionExist) {
      window.location.href = `${import.meta.env.VITE_ST_WEBSITE_DOMAIN}${import.meta.env.VITE_ST_WEBSITE_BASE_PATH}?redirectUrl=${window.location.href}`
    }
  }, [userSession])

  if (
    userSession.loading ||
    userSession.accessDeniedValidatorError != null ||
    !userSession.doesSessionExist
  ) {
    return (
      <Box
        display="flex"
        height="100vh"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <LoadingIndicator />
      </Box>
    )
  }

  return (
    <Box width="100%">
      <TopNav profile={profile} />
      <Container maxWidth="md" sx={{ mt: 8 }} component="main">
        <Outlet />
      </Container>
    </Box>
  )
}
