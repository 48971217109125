import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import Product from './Product'

import { BrandLogo } from '@/common/components/shared/BrandLogo'
import { IncomeProtectionIcon } from '@/common/components/shared/IncomeProtectionIcon'
import { TermLifeIcon } from '@/common/components/shared/TermLifeIcon'

const products = [
  {
    title: 'Short-Term Disability Insurance',
    icon: <IncomeProtectionIcon width={64} height={64} />,
    to: 'disability',
    bulletPoints: [
      'Income Replacement',
      'Temporary Coverage Period',
      'Covers Various Types of Disabilities',
    ],
  },
  {
    title: 'Term Life Insurance',
    icon: <TermLifeIcon width={64} height={64} />,
    to: 'life',
    bulletPoints: [
      'Financial Protection for Loved Ones',
      'Affordability and Simplicity',
      'Fixed Premiums for the Term',
    ],
  },
]

export const Products: React.FC = () => (
  <Stack padding={{ md: 8, sm: 2, xs: 4 }} alignItems="center" flex={1}>
    <Box paddingBottom={10}>
      <BrandLogo height={64} />
    </Box>

    <Box paddingBottom={10}>
      <Typography variant="h1" color="primary" fontWeight="bold">
        Select Your Product
      </Typography>
    </Box>

    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        flexWrap: 'wrap',
        padding: 4,
      }}
    >
      {products.map((product) => (
        <Box key={product.title} m={2}>
          <Product {...product} />
        </Box>
      ))}
    </Box>
  </Stack>
)
