import LoadingButton, { type LoadingButtonProps } from '@mui/lab/LoadingButton'
import { useFormState } from 'react-hook-form'

export type SubmitButtonProps = Omit<LoadingButtonProps, 'type'>

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  children,
  disabled,
  loading,
  ...rest
}) => {
  const { isSubmitting } = useFormState()

  return (
    <LoadingButton
      type="submit"
      disabled={isSubmitting || disabled}
      loading={isSubmitting || loading}
      variant="contained"
      color="primary"
      size="large"
      sx={{ ...rest.sx, px: 6 }}
      {...rest}
    >
      {children}
    </LoadingButton>
  )
}
