import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { QuoteCard } from '../QuoteCard'

import { TermLifeAcceptedQuote } from './TermLifeAcceptedQuote'

import { TermLifeCoverageType } from '@/gql/graphql'

export const AcceptedQuoteCard: React.FC = () => {
  const params = useParams()
  const appId = params.appId ?? ''

  const { loading, data, error } = useQuery(TermLifeAcceptedQuote, {
    variables: { appId },
    skip: appId === '',
  })

  if (error != null) throw error
  if (
    loading ||
    data?.insuranceApplication?.product.__typename !== 'TermLifeProduct' ||
    data.insuranceApplication.product.applicant?.__typename !==
      'TermLifeApplicant'
  ) {
    return
  }

  const coverages = data.insuranceApplication.product.applicant.coverages
  const quotes = data.insuranceApplication.product.quotes

  const baseCoverage = coverages.find(
    ({ coverageType }) => coverageType === TermLifeCoverageType.Base,
  )

  const totalQuote = quotes.find(
    ({ coverageType }) => coverageType === TermLifeCoverageType.Total,
  )

  if (baseCoverage == null || totalQuote == null) {
    return
  }

  const { benefitAmount, termPeriod, coverageType } = baseCoverage
  const { paymentFrequency, premiumAmount } = totalQuote

  return (
    <QuoteCard
      title="Term Life"
      baseCoverage={{ benefitAmount, termPeriod, coverageType }}
      totalPremium={{ paymentFrequency, premiumAmount }}
    />
  )
}
