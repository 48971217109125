import MuiCardHeader, {
  type CardHeaderProps as MuiCardHeaderProps,
} from '@mui/material/CardHeader'
import { styled } from '@mui/material/styles'

import { ThinkLifeIcon } from './ThinkLifeIcon'

const StyledCardHeader = styled(MuiCardHeader)<MuiCardHeaderProps>(
  ({ theme }) => ({
    'background': 'linear-gradient(90deg, #6C40E4 1.17%, #8C66EF 100%)',
    'flexDirection': 'row-reverse',
    'padding': 16,

    '& .MuiCardHeader-title': {
      textTransform: 'uppercase',
      fontWeight: '600',
      fontSize: '1.125rem',
      lineHeight: '1.75rem',
      color: theme.palette.common.white,
    },

    '& .MuiCardHeader-avatar': {
      marginRight: 0,
    },
  }),
)

export interface CardHeaderProps {
  title: string
}

export const CardHeader: React.FC<CardHeaderProps> = ({ title }) => {
  return (
    <StyledCardHeader
      title={title}
      avatar={<ThinkLifeIcon />}
    ></StyledCardHeader>
  )
}
