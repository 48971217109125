import Card, { type CardProps } from '@mui/material/Card'

import { CardContent } from './CardContent'
import { CardHeader } from './CardHeader'
import type { IncomeProtectionQuotePremium } from './types'

import {
  IncomeProtectionCoverageType,
  type IncomeProtectionQuoteCoverageInput,
} from '@/gql/graphql'

export interface QuoteCardProps {
  title?: string
  loading?: boolean
  baseCoverage: IncomeProtectionQuoteCoverageInput
  totalPremium?: IncomeProtectionQuotePremium
  cardProps?: CardProps
  children?: React.ReactNode
}

export const QuoteCard: React.FC<QuoteCardProps> = ({
  loading = false,
  baseCoverage,
  totalPremium,
  cardProps,
  children,
  title,
}) => {
  const { benefitPeriod, eliminationPeriod, benefitAmount, coverageType } =
    baseCoverage
  if (coverageType !== IncomeProtectionCoverageType.Base) {
    throw new Error('Base coverage type is not BASE')
  }

  if (benefitPeriod == null) {
    throw new Error('base coverage benefit period must be specified.')
  }

  if (eliminationPeriod == null) {
    throw new Error('base coverage elimination period must be specified.')
  }

  if (benefitAmount == null) {
    throw new Error('base coverage benefit amount must be specified.')
  }

  return (
    <Card {...cardProps}>
      {title != null && <CardHeader title={title} />}

      <CardContent
        benefitPeriod={benefitPeriod}
        eliminationPeriod={eliminationPeriod}
        benefitAmount={benefitAmount}
        loading={loading}
        premium={totalPremium}
      />

      {children}
    </Card>
  )
}
