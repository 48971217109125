import { BenefitPeriod as IncomeProtectionBenefitPeriod } from './IncomeProtection/BenefitPeriod'
import { BenefitPeriod as TermLifeBenefitPeriod } from './TermLife/BenefitPeriod'
import type { Policy } from './types'

import {
  IncomeProtectionCoverageType,
  TermLifeCoverageType,
} from '@/gql/graphql'

export interface BenefitPeriodProps {
  policy: Policy
}

export const BenefitPeriod: React.FC<BenefitPeriodProps> = ({ policy }) => {
  const applicant = policy.product.applicant

  if (applicant?.__typename === 'IncomeProtectionApplicant') {
    const baseCoverage = applicant.incomeProtectionCoverages.find(
      ({ coverageType }) => coverageType === IncomeProtectionCoverageType.Base,
    )
    if (
      baseCoverage?.benefitPeriod == null ||
      baseCoverage.eliminationPeriod == null
    ) {
      return
    }

    return (
      <IncomeProtectionBenefitPeriod
        benefitPeriod={baseCoverage.benefitPeriod}
        eliminationPeriod={baseCoverage.eliminationPeriod}
      />
    )
  }

  if (applicant?.__typename === 'TermLifeApplicant') {
    const baseCoverage = applicant.termLifeCoverages.find(
      ({ coverageType }) => coverageType === TermLifeCoverageType.Base,
    )

    if (baseCoverage?.termPeriod == null) {
      return
    }

    return <TermLifeBenefitPeriod termPeriod={baseCoverage.termPeriod} />
  }
}
