import Typography from '@mui/material/Typography'

export interface PolicyNumberProps {
  policyNumber?: string
}

export const PolicyNumber: React.FC<PolicyNumberProps> = ({ policyNumber }) => {
  if (policyNumber == null) {
    return
  }

  return (
    <Typography variant="h4" color="primary" fontWeight={400}>
      {policyNumber}
    </Typography>
  )
}
