import AppBar from '@mui/material/AppBar'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'

import { Header } from './Header'

export interface TopNavProps {
  onToggleSidebar: () => void
}

export const TopNav: React.FC<TopNavProps> = ({ onToggleSidebar }) => {
  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        bgcolor: 'background.default',
        mb: {
          xs: 3,
          sm: 3,
          md: 8,
        },
      }}
    >
      <Toolbar
        sx={{
          py: 2,
          borderBottomColor: 'grey.300',
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          boxSizing: 'border-box',
        }}
      >
        <Container
          fixed
          disableGutters
          maxWidth="xl"
          sx={{
            flexGrow: 1,
          }}
        >
          <Header onToggleSidebar={onToggleSidebar} />
        </Container>
      </Toolbar>
    </AppBar>
  )
}
