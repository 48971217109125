import { Avatar } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'
import { Link } from 'react-router-dom'
import { signOut } from 'supertokens-auth-react/recipe/session'

import { BrandLogo } from '@/common/components/shared/BrandLogo'

export interface UserProfile {
  firstName?: string
  lastName?: string
  avatarUrl?: string
}

export interface TopNavProps {
  profile: UserProfile
}

const APP_BAR_CONTENT_HEIGHT = 36

export const TopNav: React.FC<TopNavProps> = ({
  profile: { firstName, lastName, avatarUrl },
}) => {
  const handleSignOut = async () => {
    await signOut()
    window.location.reload()
  }

  const initial = `${firstName?.[0]}${lastName?.[0]}`.toUpperCase()

  return (
    <AppBar position="fixed" sx={{ backgroundColor: 'white' }}>
      <Toolbar>
        <Link to="/">
          <BrandLogo sx={{ height: APP_BAR_CONTENT_HEIGHT }} />
        </Link>

        <Box sx={{ flex: 1 }}></Box>

        <Avatar
          alt={`${firstName} ${lastName}`}
          src={avatarUrl}
          sx={{
            width: APP_BAR_CONTENT_HEIGHT,
            height: APP_BAR_CONTENT_HEIGHT,
            mr: 1,
          }}
        >
          {initial}
        </Avatar>

        <Button onClick={handleSignOut}>Logout</Button>
      </Toolbar>
    </AppBar>
  )
}
