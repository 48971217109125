import MenuIcon from '@mui/icons-material/Menu'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { ProgressStepper } from '@/common/components/ProgressStepper'
import { BrandLogo } from '@/common/components/shared/BrandLogo'

export interface HeaderProps {
  onToggleSidebar: () => void
}

export const Header: React.FC<HeaderProps> = ({ onToggleSidebar }) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  if (mdDown) {
    return (
      <Box display="flex" alignItems="center">
        <Box width={150}>
          <BrandLogo />
        </Box>
        <Box flex={1}></Box>
        <Box>
          <IconButton aria-label="toggle side bar" onClick={onToggleSidebar}>
            <MenuIcon />
          </IconButton>
        </Box>
      </Box>
    )
  }

  return <ProgressStepper />
}
