import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { IncomeProtectionEliminationPeriod } from '@/gql/graphql'

const EliminationPeriodText = {
  // accident only
  [IncomeProtectionEliminationPeriod.ZeroAccidentDays]: '0 Accident Days',
  [IncomeProtectionEliminationPeriod.SevenAccidentDays]: '7 Accident Days',
  [IncomeProtectionEliminationPeriod.FourteenAccidentDays]: '14 Accident Days',
  [IncomeProtectionEliminationPeriod.ThirtyAccidentDays]: '30 Accident Days',
  [IncomeProtectionEliminationPeriod.SixtyAccidentDays]: '60 Accident Days',
  [IncomeProtectionEliminationPeriod.NinetyAccidentDays]: '90 Accident Days',

  // accident & sickness
  [IncomeProtectionEliminationPeriod.ZeroAccidentSevenSicknessDays]:
    '0 Accident Days / 7 Sick Days',
  [IncomeProtectionEliminationPeriod.ZeroAccidentFourteenSicknessDays]:
    '0 Accident Days / 14 Sick Days',
  [IncomeProtectionEliminationPeriod.SevenAccidentSevenSicknessDays]:
    '7 Accident Days / 7 Sick Days',
  [IncomeProtectionEliminationPeriod.FourteenAccidentFourteenSicknessDays]:
    '14 Accident Days / 14 Sick Days',
  [IncomeProtectionEliminationPeriod.ThirtyAccidentThirtySicknessDays]:
    '30 Accident Days / 30 Sick Days',
  [IncomeProtectionEliminationPeriod.SixtyAccidentSixtySicknessDays]:
    '60 Accident Days / 60 Sick Days',
  [IncomeProtectionEliminationPeriod.NinetyAccidentNinetySicknessDays]:
    '90 Accident Days / 90 Sick Days',
}

export interface EliminationPeriodSectionProps {
  eliminationPeriod: IncomeProtectionEliminationPeriod
}

export const EliminationPeriodSection: React.FC<
  EliminationPeriodSectionProps
> = ({ eliminationPeriod }) => {
  return (
    <Box>
      <Typography variant="body2">Elimination Period</Typography>
      <Typography
        variant="body2"
        gutterBottom
        aria-label={EliminationPeriodText[eliminationPeriod]}
        fontWeight={600}
      >
        {EliminationPeriodText[eliminationPeriod]}
      </Typography>
    </Box>
  )
}
