import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'

export interface ProductProps {
  title: string
  icon: React.ReactNode
  bulletPoints: string[]
  to: string
}

const Product: React.FC<ProductProps> = ({ title, bulletPoints, icon, to }) => {
  return (
    <Button
      variant="outlined"
      color="primary"
      component={Link}
      to={to}
      sx={{
        display: 'block',
        width: 325,
        height: 325,
        padding: 3,
      }}
    >
      <Stack spacing={4}>
        {icon}
        <Typography variant="h3">{title}</Typography>

        <Box>
          {bulletPoints.map((point) => (
            <Box key={point} display="flex" alignItems="center" mb={1}>
              <CheckCircleOutlineRoundedIcon fontSize="small" sx={{ pr: 1 }} />
              <Typography color="text.primary">{point}</Typography>
            </Box>
          ))}
        </Box>
      </Stack>
    </Button>
  )
}

export default Product
