import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined'
import FlagIcon from '@mui/icons-material/Flag'
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined'
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined'

export const STEP_SIZE = 32

export const STEPS = [
  {
    label: 'Quote',
    icon: BusinessCenterOutlinedIcon,
  },
  {
    label: 'Personal Information',
    icon: PermIdentityOutlinedIcon,
  },
  {
    step: 3,
    label: 'Lifestyle & Health',
    icon: HealthAndSafetyOutlinedIcon,
  },
  {
    step: 4,
    label: 'Decision',
    icon: FlagIcon,
  },
]
