import { graphql } from '@/gql'

export const IncomeProtectionAcceptedQuote = graphql(`
  query IncomeProtectionAcceptedQuote($appId: ID!) {
    insuranceApplication(id: $appId) {
      id
      product {
        applicant {
          id
          ... on IncomeProtectionApplicant {
            coverages {
              benefitAmount
              benefitPeriod
              coverageType
              eliminationPeriod
              id
            }
          }
        }
        id
        ... on IncomeProtectionProduct {
          quotes {
            coverageType
            id
            paymentFrequency
            premiumAmount
          }
        }
      }
    }
  }
`)
