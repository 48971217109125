import { Navigate, type RouteObject } from 'react-router-dom'

import { AcceptedQuoteCard } from '@/common/components/IncomeProtection/AcceptedQuoteCard'
import { LegalDisclaimer } from '@/common/components/IncomeProtection/LegalDisclaimer'
import { ProductLayout } from '@/common/layouts'
import { InsuranceApplicationState } from '@/gql/graphql'
import { incomeInformationRoute } from '@/pages/IncomeProtection/IncomeInformation'
import { Root } from '@/pages/IncomeProtection/Root'

export const incomeProtectionRoute: RouteObject = {
  path: 'disability',
  element: (
    <ProductLayout
      footerSlot={<LegalDisclaimer />}
      sidebarSlot={<AcceptedQuoteCard />}
    />
  ),
  children: [
    {
      index: true,
      element: <Root />,
    },
    {
      path: ':appId',
      children: [
        {
          index: true,
          element: <Navigate to="policy" />,
        },
        {
          path: 'policy',
          lazy: async () => await import('@/pages/IncomeProtection/PolicyType'),
          handle: { allowedStates: [InsuranceApplicationState.Initial] },
        },
        {
          path: 'basic',
          lazy: async () => await import('@/pages/IncomeProtection/BasicInfo'),
          handle: { allowedStates: [InsuranceApplicationState.Initial] },
        },
        {
          path: 'occupation',
          lazy: async () =>
            await import('@/pages/IncomeProtection/OccupationClass'),
          handle: { allowedStates: [InsuranceApplicationState.Initial] },
        },
        {
          path: 'income',
          lazy: async () => await import('@/pages/IncomeProtection/Income'),
          handle: { allowedStates: [InsuranceApplicationState.Initial] },
        },
        {
          path: 'quote',
          lazy: async () => await import('@/pages/IncomeProtection/Quotes'),
          handle: { allowedStates: [InsuranceApplicationState.Initial] },
        },
        incomeInformationRoute,
        {
          path: 'birth-place',
          lazy: async () => await import('@/pages/IncomeProtection/BirthPlace'),
          handle: {
            allowedStates: [InsuranceApplicationState.Initial],
          },
        },
        {
          path: 'contact-info',
          lazy: async () =>
            await import('@/pages/IncomeProtection/ContactInfo'),
          handle: {
            allowedStates: [InsuranceApplicationState.Initial],
          },
        },
        {
          path: 'legal-detail',
          lazy: async () =>
            await import('@/pages/IncomeProtection/LegalDetail'),
          handle: {
            allowedStates: [InsuranceApplicationState.Initial],
          },
        },
        {
          path: 'permission-notice',
          lazy: async () =>
            await import('@/common/components/PermissionNotice'),
          handle: { allowedStates: [InsuranceApplicationState.Initial] },
        },
        {
          path: 'interview-questions',
          children: [
            {
              index: true,
              lazy: async () =>
                await import('@/pages/IncomeProtection/InterviewQuestions'),
            },
            {
              path: ':questionId',
              lazy: async () =>
                await import('@/pages/IncomeProtection/InterviewQuestions'),
            },
          ],
          handle: { allowedStates: [InsuranceApplicationState.Submitted] },
        },
        {
          path: 'decision',
          lazy: async () => await import('@/pages/IncomeProtection/Decisions'),
          handle: {
            allowedStates: [
              InsuranceApplicationState.Qualified,
              InsuranceApplicationState.Finalized,
            ],
          },
        },
        {
          path: 'payment',
          lazy: async () => await import('@/common/components/Payment'),
          handle: {
            allowedStates: [InsuranceApplicationState.Qualified],
          },
        },
        {
          path: 'documents',
          lazy: async () => await import('@/common/components/Documents'),
          handle: {
            allowedStates: [InsuranceApplicationState.Sealed],
            layout: { variant: 'docusign' },
          },
        },
        {
          path: 'complete',
          lazy: async () => await import('@/common/components/Complete'),
          handle: {
            allowedStates: [
              InsuranceApplicationState.Signed,
              InsuranceApplicationState.Finalized,
            ],
          },
        },
      ],
    },
  ],
}
