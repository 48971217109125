import { useQuery } from '@apollo/client'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useMemo } from 'react'

import { PolicyCard } from './Policy/Card'
import type { Policy } from './Policy/types'
import { InsuranceApplications } from './gql/InsuranceApplications'

import { LoadingIndicator } from '@/common/components/shared/LoadingIndicator'
import { PageHeading } from '@/common/components/shared/PageHeading'
import { InsuranceApplicationState, UnderwritingStatus } from '@/gql/graphql'

interface CategorizedPolicies {
  approved: Policy[]
  pending: Policy[]
  declined: Policy[]
  incomplete: Policy[]
}

export const Dashboard: React.FC = () => {
  const { loading, data, error } = useQuery(InsuranceApplications)
  const { approved, pending, declined, incomplete } = useMemo(() => {
    const result: CategorizedPolicies = {
      approved: [],
      pending: [],
      declined: [],
      incomplete: [],
    }

    if (data?.insuranceApplications == null) {
      return result
    }

    for (const application of data.insuranceApplications) {
      if (application.state === InsuranceApplicationState.Finalized) {
        switch (application.product.underwritingDecisions?.underwritingStatus) {
          case UnderwritingStatus.Approved:
          case UnderwritingStatus.ApprovedWithChanges:
            result.approved.push(application)
            break
          case UnderwritingStatus.ReferredToUnderwriter:
          case UnderwritingStatus.UnderwritingPostponed:
            result.pending.push(application)
            break
          case UnderwritingStatus.Declined:
            result.declined.push(application)
            break
          default:
            result.incomplete.push(application)
        }
      } else {
        result.incomplete.push(application)
      }
    }

    return result
  }, [data])

  if (error != null) {
    throw error
  }

  if (loading) {
    return (
      <Box
        display="flex"
        height="100vh"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <LoadingIndicator />
      </Box>
    )
  }

  return (
    <Box py={4}>
      <PageHeading>My Policies</PageHeading>

      {approved.length > 0 && (
        <>
          <Typography variant="subtitle1" gutterBottom>
            Approved
          </Typography>
          <Stack spacing={2} mb={4}>
            {approved.map((policy) => (
              <PolicyCard key={policy.id} policy={policy} status="approved" />
            ))}
          </Stack>
        </>
      )}

      {pending.length > 0 && (
        <>
          <Typography variant="subtitle1" gutterBottom>
            Pending
          </Typography>
          <Stack spacing={2} mb={4}>
            {pending.map((policy) => (
              <PolicyCard key={policy.id} policy={policy} status="pending" />
            ))}
          </Stack>
        </>
      )}

      {declined.length > 0 && (
        <>
          <Typography variant="subtitle1" gutterBottom>
            Declined
          </Typography>
          <Stack spacing={2} mb={4}>
            {declined.map((policy) => (
              <PolicyCard key={policy.id} policy={policy} status="declined" />
            ))}
          </Stack>
        </>
      )}

      {incomplete.length > 0 && (
        <>
          <Typography variant="subtitle1" gutterBottom>
            Incomplete
          </Typography>
          <Stack spacing={2} mb={4}>
            {incomplete.map((policy) => (
              <PolicyCard key={policy.id} policy={policy} status="incomplete" />
            ))}
          </Stack>
        </>
      )}
    </Box>
  )
}
