import { graphql } from '@/gql'

export const InsuranceApplications = graphql(`
  query InsuranceApplications {
    insuranceApplications {
      id
      type
      product {
        id
        applicant {
          ... on TermLifeApplicant {
            termLifeCoverages: coverages {
              coverageType
              benefitAmount
              termPeriod
            }
          }
          ... on IncomeProtectionApplicant {
            incomeProtectionCoverages: coverages {
              coverageType
              benefitAmount
              benefitPeriod
              eliminationPeriod
            }
          }
        }
        ... on TermLifeProduct {
          underwritingDecisions {
            policyNumber
            underwritingStatus
          }
        }
        ... on IncomeProtectionProduct {
          underwritingDecisions {
            policyNumber
            underwritingStatus
          }
        }
      }
      state
    }
  }
`)
