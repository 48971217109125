import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { TermLifeTermPeriod } from '@/gql/graphql'

const TermPeriodText = {
  [TermLifeTermPeriod.TenYears]: '10 Years',
  [TermLifeTermPeriod.FifteenYears]: '15 Years',
  [TermLifeTermPeriod.TwentyYears]: '20 Years',
  [TermLifeTermPeriod.ThirtyYears]: '30 Years',
}

export interface TermPeriodSectionProps {
  termPeriod: TermLifeTermPeriod
}

export const TermPeriodSection: React.FC<TermPeriodSectionProps> = ({
  termPeriod,
}) => {
  return (
    <Box>
      <Typography variant="body2">Coverage Period</Typography>
      <Typography
        variant="body2"
        gutterBottom
        aria-label={TermPeriodText[termPeriod]}
        fontWeight={600}
      >
        {TermPeriodText[termPeriod]}
      </Typography>
    </Box>
  )
}
